<template>
  <div>
    <!-- {{ filteredComposants }} -->
    <div class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des composants
      </span>
      <div class="d-flex justify-content-end">
        <CustomAddButton
          text="Ajouter composant technique"
          :disabled="disabled"
          @click="addInput"
        />
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <b-table
      responsive
      :items="tableItems"
      :fields="tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      bordered
      striped
      show-empty
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(taux)="data">
        <b-progress
          v-if="!data.item.hasOwnProperty('editMode')"
          max="100"
          show-progress
          height="15px"
          sstyle="background-color: #cacccd!important;"
        >
          <b-progress-bar
            :style="`background:${
              data.value > 20 && data.value <= 70
                ? '#f5f245'
                : data.value > 70
                ? '#23d92c'
                : '#f5ba45'
            };font-size:8px;`"
            :value="data.value"
            :label="`${data.value}%`"
          ></b-progress-bar>
        </b-progress>
        <b-progress
          v-else
          max="100"
          show-progress
          height="15px"
          sstyle="background-color: #cacccd!important;"
        >
          <b-progress-bar
            :style="`background:${
              taux > 20 && taux <= 70
                ? '#f5f245'
                : taux > 70
                ? '#23d92c'
                : '#f5ba45'
            };font-size:8px;`"
            :value="taux"
            :label="`${taux}%`"
          ></b-progress-bar>
        </b-progress>
      </template>
      <template v-slot:cell(archictecteTechnique)="data">
        <TableResponsable
          v-if="!data.item.hasOwnProperty('editMode')"
          :responsable="data.value"
        />
        <v-select disabled v-else v-model="archictecteTechnique" />
      </template>

      <template v-slot:cell(ref)="data">
        <div>
          <span
            v-if="!data.item.hasOwnProperty('editMode')"
            style="text-decoration: underline; color: blue; cursor: pointer"
          >
            CO-{{ data.item.id }}
          </span>
          <v-select v-else disabled v-model="ref" />
        </div>
      </template>
      <template v-slot:cell(status)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value || "-"
        }}</span>
        <v-select
          appendToBody
          style="min-width: max-content"
          v-else
          disabled
          v-model="status"
        />
      </template>

      <template v-slot:cell(patrimoine)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value.name || "-"
        }}</span>
        <v-select
          appendToBody
          style="min-width: max-content"
          v-else
          disabled
          v-model="status"
        />
      </template>
      <template v-slot:cell(versions)="data">
        <div class="text-center">
          <b-badge
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            v-if="data.value.length"
            @click="showVersionList(data.item)"
            pill
          >
            {{ data.value.length }}
          </b-badge>
          <b-badge
            v-else
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            pill
          >
            {{ data.value.length }}
          </b-badge>
        </div>
      </template>
      <template v-slot:cell(editeur)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          {{ data.value.name || "-" }}
        </span>
        <v-select
          appendToBody
          style="min-width: max-content"
          v-else
          disabled
          v-model="editeur.name"
        />
      </template>

      <template v-slot:cell(type)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          {{ data.value || "-" }}
        </span>
        <v-select
          appendToBody
          style="min-width: max-content"
          v-else
          disabled
          v-model="type"
        />
      </template>
      <template v-slot:cell(name)="data">
        <b-link
          :to="{
            name: 'show-composant',
            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }"
          v-if="!data.item.hasOwnProperty('editMode')"
          >{{ data.value }}</b-link
        >
        <div id="add-page" class="row col-12 p-0 m-0" v-else>
          <div
            class="font-weight-normal p-0 form-control"
            style="padding: 0 !important; height: fit-content"
            :class="{
              'is-invalid':
                $v.selectedComposant.$error && $v.selectedComposant.$dirty,
            }"
          >
            <v-select
              :options="filteredComposants"
              appendToBody
              label="name"
              v-model="selectedComposant"
              style="min-width: max-content"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </div>

          <!-- ERROR MESSAGES -->
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.selectedComposant.$dirty"
          >
            {{ !$v.selectedComposant.required ? "Champ obligatoire" : "" }}
          </span>
          <!--  -->
        </div>
      </template>
      <template v-slot:cell(applications)="data">
        <div class="text-center">
          <b-badge
            v-if="data.value.length"
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            @click="showAppsList(data.item)"
            pill
          >
            {{ data.value.length }}
          </b-badge>
          <b-badge
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            v-else
            pill
          >
            {{ data.value.length }}
          </b-badge>
        </div>
      </template>
      <template v-slot:cell(businessLine)="data">
        <span> {{ data.value.length }} </span>
      </template>

      <template #cell(actions)="data">
        <table-actions
          class="justify-content-end"
          :actions="disabled ? [] : ['delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @confirmItem="confirmItem()"
          @cancelItem="cancelItem(data.item)"
          @deleteItem="deleteItem(data.item)"
          @editItem="editItem(data.item)"
        ></table-actions>
      </template>
    </b-table>

    <b-pagination
      aria-controls="table-list"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="right"
    >
    </b-pagination>
    <!-- COMPOSANT -->
    <div v-if="showApps" to="modals-xyz-500">
      <modal @close="showApps = !showApps">
        <app-list :composant="modalSelectedComposant" />
      </modal>
    </div>

    <div v-if="showVersion" to="modals-xyz-500">
      <modal @close="showVersion = !showVersion">
        <VersionList :composant="modalSelectedComposant" />
      </modal>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import CustomAddButton from "@/components/custom/CustomAddButton";
import TableActions from "@/components/custom/TableActions.vue";
import { mapGetters } from "vuex";
import TableResponsable from "@/components/custom/TableResponsable";
import { required } from "vuelidate/lib/validators";
import Modal from "@/components/custom/Modal.vue";

import Swal from "sweetalert2";
import VersionList from "../composants/components/VersionList.vue";
import AppList from "../composants/components/AppList.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    Modal,
    AppList,
    CustomAddButton,
    TableActions,
    TableResponsable,
    VersionList,
  },

  props: {
    composants: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      sortBy: "",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableItems: [],
      tableFields: [
        // { key: "ref", label: "Réf", sortable: true },
        { key: "name", label: "Libellé", sortable: true },
        { key: "versions", label: "Versions", sortable: true },

        { key: "editeur", label: "Editeur", sortable: true },
        // { key: "patrimoine", label: "Patrimoine", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        // { key: "archictecteTechnique", label: "Architect Technique", sortable: false },
        {
          key: "type",
          label: "Type composant",
          sortable: true,
        },
        { key: "taux", label: "Taux de remplissage", sortable: true },

        {
          key: "applications",
          label: "Applications supportées",
          sortable: false,
        },
        { key: "businessLine", label: "Ligne métier", sortable: false },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        },
      ],
      ref: "",
      status: "",
      archictecteTechnique: null,
      taux: 0,
      editeur: "",
      type: "",
      selectedComposant: null,
      showApps: false,
      showVersion: false,
      modalSelectedComposant: null,
    };
  },
  validations: {
    selectedComposant: {
      required,
    },
  },
  methods: {
    addInput() {
      if (!this.disabled) {
        this.tableItems = !this.tableItems.some((item) =>
          item.editMode ? item.editMode : false
        )
          ? [
              {
                editMode: true,
              },
              ...this.tableItems,
            ]
          : this.tableItems;
        if (
          !this.tableItems.some((item) =>
            item.editMode ? item.editMode : false
          )
        ) {
          this.createdAt = new Date();
        }
        this.decision = "En attente";
      }
    },
    confirmItem() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        var newComposants = this.composants.map(
          (composant) => composant["@id"]
        );
        newComposants.unshift(this.selectedComposant["@id"]);
        this.$store
          .dispatch("capaciteTechnique/updateCapacite", {
            id: this.$route.params.id,
            composants: newComposants,
          })
          .then(() => {
            this.$store
              .dispatch(
                "capaciteTechnique/fetchCapacite",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("Le composant est bien créé !", "", "success");

                this.selectedComposant = null;
              });
          });
      }
    },
    deleteItem(item) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var newComposants = this.composants
            .filter((composant) => composant.id != item.id)
            .map((composant) => composant["@id"]);
          this.$store
            .dispatch("capaciteTechnique/updateCapacite", {
              id: this.$route.params.id,
              composants: newComposants,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "capaciteTechnique/fetchCapacite",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },
    cancelItem() {
      if (this.selectedComposant) this.selectedComposant = null;
      else
        this.$store.dispatch(
          "capaciteTechnique/fetchCapacite",
          this.$route.params.id
        );
    },
    showVersionList(composant) {
      this.modalSelectedComposant = composant;
      this.showVersion = true;
    },
    showAppsList(composant) {
      this.modalSelectedComposant = composant;
      this.showApps = true;
    },
  },
  created() {
    this.$store.dispatch("composant/fetchAllComposants");
  },
  mounted() {
    this.tableItems = this.composants;
  },

  computed: {
    ...mapGetters("composant", ["COMPOSANTS"]),
    filteredComposants() {
      return this.COMPOSANTS.filter(
        (composant) =>
          !this.composants.find((compo) => compo.id === composant.id)
      );
    },
    rows() {
      return this.tableItems.length;
    },
  },
  watch: {
    composants() {
      this.tableItems = this.composants;
    },
    selectedComposant() {
      if (this.selectedComposant) {
        this.ref = `SA${this.selectedComposant.id}`;
        this.status = this.selectedComposant.status;
        // this.archictecteTechnique = `${this.selectedComposant.po.firstname} ${this.selectedComposant.po.lastname}`;
        this.taux = this.selectedComposant.taux;
        this.editeur = this.selectedComposant.editeur;
        this.type = this.selectedComposant.type;
      } else {
        this.ref = "";
        this.status = "";
        // this.archictecteTechnique = null;
        this.taux = 0;
        this.editeur = "";
      }
    },
  },
};
</script>
