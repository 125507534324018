<template>
  <div>
    <div class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des cadres rattachés à la capacité technique
      </span>
      <div class="d-flex justify-content-end">
        <CustomAddButton
          text="Ajouter un cadre"
          :disabled="disabled"
          @click="addInput"
        />
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <!-- {{ this.CADRES.cadreEntreprises }} -->
    <b-table
      :items="tableItems"
      responsive
      :fields="tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      bordered
      striped
      show-empty
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>

      <template v-slot:cell(type)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value || "-"
        }}</span>
        <v-select
          v-else
          appendToBody
          :options="['Pattern', 'Règle', 'Politique', 'Principe']"
          v-model="status"
          @input="selectedCadre = null"
          placeholder="Type"
        />
      </template>

      <template v-slot:cell(name)="data">
        <b-link
          v-if="!data.item.hasOwnProperty('editMode')"
          :to="{
            name: 'show-principe-ref',
            params: {
              id: data.item.id,
              slug: data.item.slug,
            },
          }"
          >{{ data.value }}
        </b-link>
        <div id="add-page" class="row col-12 p-0 m-0" v-else>
          <div
            class="font-weight-normal p-0 form-control"
            style="padding: 0 !important; height: fit-content"
            :class="{
              'is-invalid': $v.selectedCadre.$error && $v.selectedCadre.$dirty,
            }"
          >
            <v-select
              :options="filtredCadres"
              appendToBody
              label="text"
              v-model="selectedCadre"
              placeholder="Cadre"
              :reduce="(cadre) => cadre.value"
              style="min-width: max-content"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </div>

          <!-- ERROR MESSAGES -->
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.selectedCadre.$dirty"
          >
            {{ !$v.selectedCadre.required ? "Champ obligatoire" : "" }}
          </span>
          <!--  -->
        </div>
      </template>

      <template #cell(actions)="data">
        <table-actions
          class="justify-content-end"
          :actions="disabled ? [] : ['delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @confirmItem="confirmItem()"
          @cancelItem="cancelItem(data.item)"
          @deleteItem="deleteItem(data.item)"
          @editItem="editItem(data.item)"
        ></table-actions>
      </template>
    </b-table>
    <b-pagination
      aria-controls="table-list"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="right"
    >
    </b-pagination>
  </div>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableActions,
    CustomAddButton,
  },
  props: {
    cadres: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      sortBy: "",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedCadre: null,
      tableFields: [
        { key: "type", label: "Type cadre", sortable: true },
        { key: "name", label: "Libellé cadre", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
      tableItems: [],
      ref: "",
      status: "",
      po: null,
      taux: 0,
    };
  },
  validations: {
    selectedCadre: {
      required,
    },
  },
  created() {
    this.$store.dispatch("cadre/fetchAllCadres");
    this.$store.dispatch(
      "capaciteTechnique/fetchCapaciteTechniqueCadres",
      this.$route.params.id
    );
  },

  computed: {
    ...mapGetters("application", ["APPLICATIONS"]),
    ...mapGetters("cadre", ["CADRE_ENTREPRISES"]),
    ...mapGetters("capaciteTechnique", ["CADRES"]),

    filtredCadres() {
      return this.CADRE_ENTREPRISES.filter(
        (cadre) =>
          cadre.type === this.status &&
          !this.CADRES.cadreEntreprises.find((c) => c["@id"] === cadre["@id"])
      ).map((cadre) => ({
        text: cadre.name,
        value: cadre["@id"],
      }));
    },
    rows() {
      return this.tableItems.length;
    },
  },
  methods: {
    addInput() {
      if (!this.disabled) {
        this.tableItems = !this.tableItems.some((item) =>
          item.editMode ? item.editMode : false
        )
          ? [
              {
                editMode: true,
              },
              ...this.tableItems,
            ]
          : this.tableItems;
        if (
          !this.tableItems.some((item) =>
            item.editMode ? item.editMode : false
          )
        ) {
          this.createdAt = new Date();
        }
      }
    },
    confirmItem() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        var newCadre = this.CADRES.cadreEntreprises.map(
          (application) => application["@id"]
        );
        newCadre.unshift(this.selectedCadre);
        this.$store
          .dispatch("capaciteTechnique/updateCapacite", {
            id: this.$route.params.id,
            cadreEntreprises: newCadre,
          })
          .then(() => {
            this.$store
              .dispatch(
                "capaciteTechnique/fetchCapaciteTechniqueCadres",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire(
                  "Le cadre d’entreprise est bien créé !",
                  "",
                  "success"
                );

                this.selectedCadre = null;
                this.status = "";
              });
          });
      }
    },
    deleteItem(item) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var newCadre = this.CADRES.cadreEntreprises
            .filter((cadre) => cadre.id != item.id)
            .map((cadre) => cadre["@id"]);
          this.$store
            .dispatch("capaciteTechnique/updateCapacite", {
              id: this.$route.params.id,
              cadreEntreprises: newCadre,
            })
            .then(() => {
              this.$store
                .dispatch(
                  "capaciteTechnique/fetchCapaciteTechniqueCadres",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },

    cancelItem() {
      if (this.selectedCadre === null && this.status === "")
        this.tableItems = [];
      else {
        this.selectedCadre = null;
        this.status = "";
      }
    },
  },
  watch: {
    CADRES() {
      this.tableItems = this.CADRES.cadreEntreprises;
    },
    // selectedCadre() {
    //   if (this.selectedCadre) {
    //     this.ref = `SA${this.selectedCadre.id}`;
    //     this.status = this.selectedCadre.status;
    //     this.taux = this.selectedCadre.taux;
    //   } else {
    //     this.ref = "";
    //     this.status = "";
    //     this.po = null;
    //     this.taux = 0;
    //   }
    // },
  },
};
</script>
